import { combineReducers } from 'redux';

import entities from './entities'
import reducer from './reducer'


const rootReducer = combineReducers({
    entities,
    reducer,
});

export default rootReducer;