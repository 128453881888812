import React, { Fragment } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { connect } from 'react-redux';
import { logOutUser } from 'actions/auth';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent({ ...props }) {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;

    async function logout() {
        props.dispatch(logOutUser(res => {
            push(SLUGS.login);
        }))
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }
    const { LoginData } = props.data;
    let mainadmin = LoginData.moderatortype == 0 ? true : false
    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            {/* <MenuItem
                id={SLUGS.dashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.dashboard)}
            /> */}
            {mainadmin ? <Fragment>
                <MenuItem
                    id={SLUGS.users}
                    title='Users'
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.users)}
                />
                <MenuItem
                    id={SLUGS.bonsai}
                    // items={[SLUGS.overviewTwo, SLUGS.overviewThree]}
                    onClick={() => onClick(SLUGS.bonsai)}
                    title='Bonsai'
                    icon={IconOverview}
                />
                <MenuItem
                    id={SLUGS.tasks}
                    title='Tasks'
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.tasks)}
                />
                <MenuItem
                    id={SLUGS.notification}
                    title='Notifications'
                    icon={IconIdeas}
                    onClick={() => onClick(SLUGS.notification)}
                />
                <MenuItem
                    id={SLUGS.activities}
                    title='Activity Requests'
                    icon={IconIdeas}
                    onClick={() => onClick(SLUGS.activities)}
                />
                <MenuItem
                    id={SLUGS.moderators}
                    title='Moderators'
                    icon={IconIdeas}
                    onClick={() => onClick(SLUGS.moderators)}
                />
            </Fragment>
                :
                <Fragment>
                    <MenuItem
                        id={SLUGS.users}
                        title='Users'
                        icon={IconContacts}
                        onClick={() => onClick(SLUGS.users)}
                    />
                    <MenuItem
                        id={SLUGS.bonsai}
                        // items={[SLUGS.overviewTwo, SLUGS.overviewThree]}
                        onClick={() => onClick(SLUGS.bonsai)}
                        title='Bonsai'
                        icon={IconOverview}
                    />
                    <MenuItem
                        id={SLUGS.tasks}
                        title='Tasks'
                        icon={IconArticles}
                        onClick={() => onClick(SLUGS.tasks)}
                    />
                    <MenuItem
                        id={SLUGS.notification}
                        title='Notifications'
                        icon={IconIdeas}
                        onClick={() => onClick(SLUGS.notification)}
                    />
                    <MenuItem
                        id={SLUGS.activities}
                        title='Activity Requests'
                        icon={IconIdeas}
                        onClick={() => onClick(SLUGS.activities)}
                    />
                </Fragment>
            }

            <div className={classes.separator}></div>
            <MenuItem
                id={SLUGS.settings}
                title='Settings'
                icon={IconSettings}
                onClick={() => onClick(SLUGS.settings)}
            />

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}



function mapStateToProps(state) {
    const { entities } = state;
    return {
        data: entities.auth,
    }
}


export default connect(mapStateToProps)(SidebarComponent);
