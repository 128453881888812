
import { getAllUsers, sendNotification } from 'actions/user';
import LoadingComponent from 'components/loading';
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import {
    InputGroup,
    InputGroupAddon,
    Button,
    Input,
    FormGroup,
} from "reactstrap";
let ch = require("../../icons/ic_check.png")
let unch = require("../../icons/ic_check_gray.png")

var allUserData = [];


const CheckIt = ({ name, isCheck, Checked }) => {
    const [chk, onCheck] = useState(isCheck)
    return <div style={{ flex: 1, height: 40, borderWidth: 0.5, borderColor: "#ccc", borderStyle: 'solid', paddingLeft: 10, paddingRight: 10, margin: 5, flexDirection: 'row', display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 'bold', justifyContent: 'space-between', pointerEvents: 'auto', backgroundColor: "#fff" }}>
        {name}
        <img
            src={chk ? ch : unch}
            onClick={() => { onCheck(!chk); Checked(!chk) }}
            style={{ height: 20, width: 20, }}
        />
    </div>
}

let userkeys = 1

const Notifications = ({ ...props }) => {
    const [all, onSelect] = useState(false)
    const [title, onTitle] = useState("")
    const [desc, onDesc] = useState("")
    const [allUsers, onAllUers] = useState([])
    const [query, onQuery] = useState("")
    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = () => {
        props.dispatch(getAllUsers(res => {
            let newArr = []
            res.forEach(item => {
                let obj = item
                obj.isSelected = false
                newArr.push(obj)
            })
            allUserData = [...newArr]
            onAllUers([...newArr])
            userkeys = userkeys + 1
        }))
    }

    const onSelectAll = () => {
        let arr = [...allUsers]
        let newArr = []
        arr.forEach(item => {
            item.isSelected = !all
            newArr.push(item)
        })
        onAllUers([...newArr])
        onSelect(!all)
        userkeys = userkeys + 1
    }

    const sendNotifcation = () => {
        let obj = {
            "title": title,
            "message": desc,
            "typeAll": all,
        }

        if (title.trim() !== "" && desc.trim() !== "") {
            if (!all) {
                let arr = [...allUsers]
                let iOSArr = []
                let andArr = []
                let newArr = []
                arr.forEach(el => {
                    if (el.isSelected && el.devicetoken) {
                        if (el.devicetype == "ios") {
                            iOSArr.push(el.devicetoken)
                            newArr.push(el.devicetoken)
                        }
                        else {
                            andArr.push(el.devicetoken)
                            newArr.push(el.devicetoken)
                        }
                    }
                });
                obj.androiddevicetokens = andArr
                obj.iosdevicetokens = iOSArr
                if (newArr.length == 0) {
                    alert("Please select users")
                }
                else {
                    SendFinal(obj)
                }
            }
            else {
                SendFinal(obj)
            }
        }
        else {
            alert("Please enter valid Notification Title and Message")
        }
    }

    const SendFinal = (fin) => {
        props.dispatch(sendNotification(fin, res => {
            if (res) {
                alert("Notification sent successfully")
                onSelect(false)
                onTitle("")
                onDesc("")
                getUsers()
                onQuery("")
                userkeys = userkeys + 1
            }
        }))
    }

    const alterItems = (item, e) => {
        console.log(item);
        let arr = [...allUsers]
        let prevarr = [...allUserData]
        let indOne = arr.findIndex(e => e._id == item._id)
        let indTwo = prevarr.findIndex(e => e._id == item._id)
        arr[indOne].isSelected = e
        prevarr[indTwo].isSelected = e
        onSelect(false)
        userkeys = userkeys + 1;
    }

    const handleUserQuery = (quer) => {
        const newData = allUserData.filter(function (item) {
            const itemfname = item.email.toUpperCase()
            const textData = quer.toUpperCase()
            return (
                itemfname.indexOf(textData) > -1
            );
        });
        onAllUers([...newData])
        onQuery(quer)
        userkeys = userkeys + 1;
    }

    const { isFetching } = props.reducer

    return <div>
        <div style={{ display: 'flex', flexDirection: 'row', height: "100%" }}>
            <div style={{ flex: 1.5, padding: 20 }}>
                <h4>Select Users</h4>
                <InputGroup style={{ width: "100%", marginBottom: 30, justifyContent: 'space-between' }}>
                    <Input
                        placeholder="Search by user email..."
                        value={query}
                        onChange={evt => handleUserQuery(evt.target.value)}
                    />
                    <InputGroupAddon addonType="append"><Button color="secondary">Search</Button></InputGroupAddon>
                    <div onClick={onSelectAll} style={{ display: 'flex', flex: 0.3, marginLeft: 10, paddingRight: 10, paddingLeft: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderStyle: 'solid', cursor: 'pointer', borderColor: all ? "orange" : "darkgrey" }}>
                        Select All
                        <img
                            src={all ? ch : unch}
                            style={{ height: 20, width: 20 }}
                        />
                    </div>
                </InputGroup>
                <div key={userkeys} style={{ overflowY: 'auto', height: 600 }}>
                    {allUsers.map((item, index) => <CheckIt
                        name={item.email}
                        isCheck={item.isSelected}
                        Checked={(e) => alterItems(item, e)} />)}
                </div>
            </div>
            <div style={{ flex: 2, padding: 20 }}>
                <h4>Notification Title</h4>
                <FormGroup>
                    <Input
                        placeholder={"Enter title..."}
                        onChange={evt => onTitle(evt.target.value)}
                        value={title}
                    // type="textarea"
                    />
                </FormGroup>
                <h4>Notification Body</h4>
                <FormGroup>
                    <Input
                        placeholder={"Enter body..."}
                        onChange={evt => onDesc(evt.target.value)}
                        value={desc}
                        type="textarea"
                    />
                </FormGroup>
                <FormGroup>
                    <Button onClick={sendNotifcation} color={'success'}>
                        Send
                    </Button>
                </FormGroup>
            </div>
        </div>
        <LoadingComponent loading={isFetching} />
    </div>
}

function mapStateToProps(state) {
    const { entities, reducer } = state;
    return {
        data: entities.users,
        tasks: entities.tasks,
        user: entities.auth,
        reducer
    }
}


export default connect(mapStateToProps)(Notifications);
