export const API_ROOT = 'http://app.jin-spire.be:3000/';
export const IMG_ROOT = "http://app.jin-spire.be:3000/public/uploads/"
export const PROFILE_IMG_ROOT = "http://app.jin-spire.be:3000/public/profileimage/"
export const TASK_IMG_ROOT = "http://app.jin-spire.be:3000/public/taskimages/"
export const ASSIGNTASK_IMG_ROOT = "http://app.jin-spire.be:3000/public/assigntaskimages/"
export const USER_AUTH = "USER";
export const USER_TOKEN = "TOKEN";
export const AppURLs = {
    login: 'adminlogin',
    logOut: "adminlogout",
    updateUser: "adminuserupdate",
    getAllUsers: "getalluserbyadmin/",
    getAllBonsai: "getallbonsaibyadmin/",
    deleteBonsai: "deletebonsaibyadmin/",
    deleteUser: "deleteUserbyadmin/",
    getUsersBonsai: "getallbonsaifromuserbyadmin/",
    getAllAssignTasks: "getallassigntaskbyadmin/",
    getalltasks: "getalltasks",
    deleteTask: "tasksdelete/",
    deleteTaskIns: "bonsaitasksinstructiondelete/",
    createtask: "createtask",
    tasksDelete: "tasksdelete/",
    assignTasks: "assigntaskbyadmin/",
    getBonsaiTasks: "getallAssigntaskofbonsaibyadmin/",
    updateStatus: "updateuserpaidstatusbyadmin/",
    assigntaskdonebyadmin: "assigntaskdonebyadmin/",
    createInstructions: "bonsaitasksinstructionupdatebyadmin/",
    sendNotification: "sendnotificationtouserbyadmin/",
    getModerators: "getallsubuserbyadmin/",
    addModerator: "subadminsignup",
    removeModerator: "deletesubadmin",

    // Moderators
    getUserOfModerator: "getmoderatoruserbyadmin",
    getavailableusers: "getallavailableuserbyadmin",
    addusertogroup: "addusertomoderatorbyadmin",
    removeuserfromgroup: "removeuserfrommoderatorbyadmin"
};

export const Dummy = {
    tasks: [
        {
            id: 1,
            title: "Protect dead wood",
            completed: true
        },
        {
            id: 2,
            title: "Prune",
            completed: true
        },
        {
            id: 3,
            title: "Redesign",
            completed: false
        },
        {
            id: 4,
            title: "Remodel",
            completed: false
        },
        {
            id: 5,
            title: "Remove leaves",
            completed: false
        }
    ],
    allTasks: [
        "Add liquid fertilizer",
        "Add solid fertilizer",
        "Apply phytosanitary",
        "Breaking candles(Metsumi)",
        "Check wiring",
        "Clean deadwood",
        "Cut Unnecessary branches",
        "Define living vein",
        "Defoliate",
        "Design",
        "Expand shari",
        "Fertilize(foliar)",
        "Fertilize(root)",
        "Label substrate",
        "Let grow",
        "Let rest",
        "Location change",
        "Other",
        "Peel dead branches",
        "Pinch",
        "Protect deadwood",
        "Prune",
        "Prunde candles(Mekiri)",
        "Redesign",
        "Remodel",
        "Remove compost",
        "Remove leaves(autumn)",
        "Remove leaves of the original plant",
        "Remove needles",
        "Remove old needles",
        "Remove weeds",
        "Remove wire",
        "Repotting",
        "Restyling",
        "Sanitize deadwood",
        "Sanitize: Remove dead, weak, diseased or unnecessary leaves and branches",
        "To model",
        "Trim needles",
        "Wire",
        "Work deadwood",
    ]
}
