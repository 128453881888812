export default {
    // private
    users: '/users',
    bonsai: '/bonsai',
    tasks: '/tasks',
    notification: '/notifications',
    settings: '/settings',
    activities: '/activities',
    moderators: '/moderators',
    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot_password'
};
