import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import { connect } from 'react-redux';

function Routes({ ...props }) {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    // const isAuthenticated = true
    const { isAuthenticated } = props.data
    return isAuthenticated ? <PrivateSection /> : <PublicRoutes />;
}


function mapStateToProps(state) {
    const { entities } = state;
    return {
        data: entities.auth,
    }
}


export default connect(mapStateToProps)(Routes);
