import LoadingComponent from 'components/loading';
import { PROFILE_IMG_ROOT } from '../../constants';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputGroup, Input, Row, Col, Button, FormGroup } from 'reactstrap';
import { updateAdminProfile } from 'actions/auth';
import { confirmAlert } from 'react-confirm-alert';
var tempPath = null
class Settings extends Component {

    state = {
        email: "",
        avatar: "",
        firstname: "",
        lastname: "",
        upload: false
    }

    componentDidMount() {
        const { LoginData } = this.props.user
        const { firstname, lastname, avatarPath, email } = LoginData
        this.setState({
            firstname: firstname,
            lastname: lastname,
            avatar: avatarPath,
            email: email
        })
    }

    handleImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = event => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = event => {
                    const uri = event.target.src;
                    tempPath = uri
                    this.setState({ avatar: file, upload: true })
                };
            };
            reader.readAsDataURL(file)
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    subMit = () => {
        const { firstname, lastname, upload, avatar } = this.state;
        if (firstname.trim().length == 0) {
            alert("First name cannot be empty")
        }
        else if (lastname.trim().length == 0) {
            alert("Last name cannot be empty")
        }
        else {
            this.props.dispatch(updateAdminProfile(upload, firstname, lastname, avatar, res => {
                if (res) {
                    confirmAlert({
                        title: 'Success',
                        message: 'Profile updated successfully.',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => { }
                            },
                        ]
                    });
                }
            }))
        }
    }

    render() {
        const { isFetching } = this.props.reducer
        const { email, avatar, firstname, lastname, upload } = this.state;
        return (
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <div class="image-upload">
                            <label for="file-input">
                                <img
                                    src={upload ? tempPath : PROFILE_IMG_ROOT + avatar}
                                    alt="Profile Image"
                                    className="rounded-circle"
                                    width="100"
                                    height="100"
                                />
                                <i style={{ fontWeight: 'bolder', color: '#fff' }} className={"ti-pencil"} />
                            </label>
                            <input style={{ display: 'block' }} id="file-input" type="file" onChange={this.handleImage} name="myFile" accept="image/x-png,image/jpeg" />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="md">
                            <Input
                                value={firstname}
                                placeholder={"Enter First Name"}
                                name="firstname"
                                onChange={this.onChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="md">
                            <Input
                                value={lastname}
                                name={"lastname"}
                                placeholder={"Enter Last Name"}
                                onChange={this.onChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup size="md">
                            <Input
                                contentEditable={'false'}
                                placeholder={"Enter Email"}
                                value={email}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" className="button" onClick={this.subMit}>
                            Edit Profile
                  </Button>
                    </FormGroup>
                </Col>
                <LoadingComponent loading={isFetching} />
            </Row>
        )
    }
}

function mapStateToProps(state) {
    const { entities, reducer } = state;
    return {
        data: entities.bonsai,
        tasks: entities.tasks,
        user: entities.auth,
        reducer
    }
}


export default connect(mapStateToProps)(Settings);
