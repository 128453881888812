

const authSchema = {
  LoginData: {},
  isAuthenticated: false,
};

const usersSchema = {
  users: [],
  allModerator: []
};

const bonsaiSchema = {
  allBonsai: [],
  userBonsai: [],
}

const taskSchema = {
  tasks: [],
  prevTask: [],
  activities: [],
  indtasks: []
}

export const Schemas = {
  AUTH: authSchema,
  USERS: usersSchema,
  BONSAI: bonsaiSchema,
  TASKS: taskSchema
};
