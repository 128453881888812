import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const UserComponent = lazy(() => import('../views/users'));
const BonsaiComponent = lazy(() => import('../views/bonsai'));
const TasksComponent = lazy(() => import('../views/tasks'));
const NotificationsComponent = lazy(() => import('../views/notifications'));
const ActivityComponent = lazy(() => import('../views/activities'));
const SettingsComponent = lazy(() => import('../views/settings'));
const ModeratorComponent = lazy(() => import("../views/moderators"))

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                {/* <Route exact path={SLUGS.dashboard} component={DashboardComponent} /> */}
                <Route exact path={SLUGS.users} component={UserComponent} />
                <Route exact path={SLUGS.bonsai} component={BonsaiComponent} />
                <Route exact path={SLUGS.tasks} component={TasksComponent} />
                <Route exact path={SLUGS.notification} component={NotificationsComponent} />
                <Route exact path={SLUGS.activities} component={ActivityComponent} />
                <Route exact path={SLUGS.settings} component={SettingsComponent} />
                <Route exact path={SLUGS.moderators} component={ModeratorComponent} />
                <Redirect to={SLUGS.users} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
