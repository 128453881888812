import axios from 'axios';
import { AppURLs } from '../../constants';
import { apiFailure, apiRequest, apiSuccess } from 'actions/action-creators';


export function getAllUsers(cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.get(AppURLs.getAllUsers).then(res => {
            if (res.status == 200) {
                getState().entities.users.users = res.data
                cb(res.data)
            }
            dispatch(apiSuccess())
        })
            .catch(err => {
                console.log(err);
                debugger
                cb([])
                dispatch(apiFailure())
            })

    }
}


export function getAllModeratorUsers(cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.get(AppURLs.getUserOfModerator).then(res => {
            debugger
            if (res.status == 200) {
                getState().entities.users.users = res.data.data
                cb(res.data.data)
            }
            dispatch(apiSuccess())
        })
            .catch(err => {
                console.log(err);
                debugger
                cb([])
                dispatch(apiFailure())
            })

    }
}

export function deleteUsers(data, cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.post(AppURLs.deleteUser, data).then(res => {
            if (res.status == 200) {
                cb()
            }
        })
            .catch(err => {
                //debugger
                cb()
                dispatch(apiFailure())
            })

    }
}

export function updatePaidStatus(data, cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.post(AppURLs.updateStatus, data).then(res => {
            if (res.status == 200) {
                cb()
            }
        })
            .catch(err => {
                cb()
                //debugger
                dispatch(apiFailure())
            })
    }
}

export function sendNotification(data, cb) {
    console.log(data);
    debugger
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.post(AppURLs.sendNotification, data).then(res => {
            if (res.status == 200) {
                cb(true)
            }
            else {
                cb(true)
            }
        })
            .catch(err => {
                cb(true)
                dispatch(apiFailure())
            })
    }
}


export function getAllAvailableUsers(cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.get(AppURLs.getavailableusers).then(res => {
            debugger
            if (res.status == 200) {
                cb(true, res.data.data)
            }
            dispatch(apiSuccess())
        })
            .catch(err => {
                debugger
                dispatch(apiFailure())
            })
    }
}

export function alterUserInGroup(obj, cb) {
    let url = obj.userstatus ? AppURLs.removeuserfromgroup : AppURLs.addusertogroup
    let email = {
        useremail: obj.email
    }
    debugger
    return (dispatch, getState) => {
        dispatch(apiRequest())
        return axios.post(url, email).then(res => {
            debugger
            if (res.status == 200) {
                cb(true, res.data.data)
            }
            dispatch(apiSuccess())
        })
            .catch(err => {
                debugger
                dispatch(apiFailure())
            })
    }
}