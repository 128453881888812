import React from 'react';

let USER_AUTH = "USER_AUTH"
let USER_TOKEN = "USER_TOKEN"
export const deleteToken = async () => {
    await localStorage.removeItem(USER_TOKEN);
};
export const setUserToken = async (token) => {
    deleteToken();
    await localStorage.setItem(USER_TOKEN, token, (err) => {
        if (err) {
            throw err;
        }
    });
};

export const getUserToken = async () => {
    const value = await localStorage.getItem(USER_TOKEN);
    if (value !== null) {
        return value;
    }
    return '';
};

export const setUserAuth = async (auth) => {
    deleteAuth();
    await localStorage.setItem(USER_AUTH, JSON.stringify(auth), (err) => {
        if (err) {
            throw err;
        }
    });
};

export const getUserAuth = async (auth) => {
    const value = await localStorage.getItem(USER_AUTH);
    if (value !== null) {
        return JSON.parse(value);
    }

    return '';
};

export const deleteAuth = async () => {
    await localStorage.removeItem(USER_AUTH);
};


export const set = async (key, value) => {
    await localStorage.setItem(key, value, (err) => {
        if (err) {
            throw err;
        }
    });
}

export const setObject = async (key, value) => {
    await localStorage.setItem(key, JSON.stringify(value), (err) => {
        if (err) {
            throw err;
        }
    });
}

export const get = async (key) => {
    return await localStorage.getItem(key);
};

export const getObject = async (key) => {
    const value = await localStorage.getItem(key);
    if (value !== null) {
        return JSON.parse(value);
    }
    return '';
};

export const remove = async (key) => {
    await localStorage.removeItem(key);
};

