
import { setUserAuth, setObject, getObject, setUserToken, deleteToken, deleteAuth } from '../../middleware'
import axios from 'axios';
import { API_ROOT, AppURLs } from '../../constants';
import { apiFailure, apiRequest, apiSuccess } from 'actions/action-creators';


export function login(data, cb) {
    deleteToken()
    return (dispatch, getState) => {
        dispatch(apiRequest());
        return axios.post(AppURLs.login, data).then(res => {
            if (res.status == 200) {
                setUserAuth(res.data.adminuser)
                dispatch(setUserdata(res.data.adminuser))
                setUserToken(res.data.token)
                getState().entities.auth.isAuthenticated = true
                cb(true)
            }
            else {
                cb(false)
            }
            dispatch(apiSuccess());
        })
            .catch(err => {
                if (err.response.data) {
                    let msg = err.response.data.Error
                    cb(false, msg)
                }
                else {
                    cb(false, "Something went wrong")
                }
                dispatch(apiFailure());
            })
    }
}

export function logOutUser(cb) {
    return (dispatch, getState) => {
        dispatch(apiRequest());
        return axios.get(AppURLs.logOut).then(res => {
            deleteAuth()
            deleteToken()
            getState().entities.auth.isAuthenticated = false
            cb(true)
            dispatch(apiSuccess());
        })
            .catch(err => {
                cb(true)
                console.log(err);
                dispatch(apiFailure());
                //debugger
            })

    }
}



export function updateAdminProfile(isImage, fname, lname, avatar, cb) {
    var data = new FormData();
    if (isImage) {
        data.append('avatar', avatar);
    }
    data.append("firstname", fname);
    data.append("lastname", lname);
    return (dispatch, getState) => {
        dispatch(apiRequest());
        return axios.post(AppURLs.updateUser, data).then(res => {
            if (res.status == 200) {
                setUserAuth(res.data)
                dispatch(setUserdata(res.data))
                dispatch(apiSuccess());
                cb(true)
            }
            else {
                dispatch(apiSuccess());
                cb(false)
            }
        })
            .catch(err => {
                cb(false)
                dispatch(apiFailure());
            })
    }
}

export function setUserdata(data) {
    return (dispatch, getState) => {
        getState().entities.auth.LoginData = data
        dispatch(apiSuccess());
    }
}

