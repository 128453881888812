import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from 'react-router-dom';
import { convertSlugToUrl } from 'resources/utilities';
import SLUGS from "resources/slugs";
import { connect } from "react-redux";
import { login, setUserdata } from "actions/auth";
import { loginCredentialCheck } from "utils/validate";
import { getUserAuth } from "middleware";
import _ from 'lodash'
import LoadingComponent from "components/loading";
function Login({ ...props }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { push } = useHistory();

    // useEffect(() => {
    //     const { LoginData } = props.data
    //     debugger
    //     if (!_.isEmpty(LoginData)) {
    //         debugger
    //         onClick(SLUGS.users)
    //     }
    //     else {
    //         debugger
    //         getUserAuth().then(res => {
    //             if (res) {
    //                 props.dispatch(setUserdata(res))
    //                 onClick(SLUGS.users)
    //             }
    //         })
    //     }
    // }, [0])

    function handleSubmit(event) {
        event.preventDefault();
        if (loginCredentialCheck(email, password)) {
            let data = {
                email: email,
                password: password,
            };
            props.dispatch(login(data, (res, msg) => {
                if (res) {
                    onClick(SLUGS.users)
                }
                else {
                    alert(msg)
                }
            }))
        }
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }
    const { isFetching } = props.reducer
    return (
        <body class="login">

            <div class="container">
                <div class="menu-toggler sidebar-toggler">
                </div>
                <div class="logo">
                    <a href="javascript:void(0)">
                        <img width="100px" src={require('../../icons/logo.png')} style={{ alignSelf: 'center' }} />
                    </a>
                </div>
                <div class="content">
                    <form class="login-form" onSubmit={handleSubmit}>
                        <h3 class="form-title">Sign In</h3>
                        <div class="alert alert-danger display-hide">
                            <button class="close" data-close="alert"></button>
                            <span>
                                Enter any username and password. </span>
                        </div>

                        <div class="form-group">
                            <label class="control-label visible-ie8 visible-ie9">Email</label>
                            <input value={email} onChange={evt => setEmail(evt.target.value)} class="form-control form-control-solid placeholder-no-fix" type="text" autocomplete="off" placeholder="Email" name="email" />
                        </div>
                        <div class="form-group">
                            <label class="control-label visible-ie8 visible-ie9">Password</label>
                            <input class="form-control form-control-solid placeholder-no-fix" type="password" value={password} onChange={evt => setPassword(evt.target.value)} autocomplete="off" placeholder="Password" name="password" />
                        </div>
                        <div class="form-actions">
                            <button type={'button'} onClick={handleSubmit} class="btn btn-success uppercase admin_login">Login</button>
                        </div>

                    </form>
                </div>
                <div class="copyright">
                    ©All Rights Reserved.
                </div>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous" />

                <link href="{{asset('assets/admin/global/css/components-rounded.css')}}" id="style_components" rel="stylesheet" type="text/css" />
            </div>
            <LoadingComponent loading={isFetching} />
        </body>

    );
}



function mapStateToProps(state) {
    const { entities, reducer } = state;
    return {
        data: entities.auth,
        reducer
    }
}


export default connect(mapStateToProps)(Login);
