import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from 'resources/theme';
import Routes from 'routes';
import configureStore from './store/stor-config'
import { Provider } from 'react-redux'
import './index.css';
import * as serviceWorker from './serviceWorker';
import './assets/scss/style.css';
import './middleware/http.Interceptor';
import Settings from 'views/settings';
import Notifications from 'views/notifications';
const App = () => <Provider store={configureStore()}>
    <ThemeProvider theme={Theme}>
        <Router>
            <Routes />
            {/* <Notifications /> */}
        </Router>
    </ThemeProvider>
</Provider>

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
