
import {
    API_REQUEST,
    API_SUCCESS,
    API_FAILURE,
} from './action-type';

export function apiRequest() {
    return {
        type: API_REQUEST
    };
}

export function apiSuccess(payload) {
    return {
        type: API_SUCCESS,
        entities: payload
    };
}

export function apiFailure(message) {
    return {
        type: API_FAILURE,
        error: message
    };
}
