let passwordLen = 6

export function isValidEmail(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email.trim());
}

export function loginCredentialCheck(email, password) {
    if (!isValidEmail(email)) {
        alert("Please enter valid email")
        return false
    }
    else if (password.length < passwordLen) {
        alert(`Password should be atleast ${passwordLen} characters long`)
        return false
    }
    else {
        return true
    }
}