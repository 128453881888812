import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from 'hooks/useSidebar';
import SLUGS from 'resources/slugs';
import { connect } from 'react-redux';
import { PROFILE_IMG_ROOT } from '../../constants';

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function HeaderComponent({ ...props }) {
    const { push } = useHistory();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });

    let title;
    switch (true) {
        case currentItem === SLUGS.users:
            title = 'Users';
            break;
        case currentItem === SLUGS.bonsai:
            title = 'Bonsai';
            break;
        case currentItem === SLUGS.tasks:
            title = 'Tasks';
            break;
        case currentItem === SLUGS.notification:
            title = 'Notifications';
            break;
        case currentItem === SLUGS.activities:
            title = 'Activities';
            break;
        case currentItem === SLUGS.settings:
            title = 'Settings';
            break;
        case currentItem === SLUGS.moderators:
            title = 'Moderators';
            break;
        default:
            title = '';
    }

    const { LoginData } = props.data
    const { firstname, lastname, avatarPath } = LoginData

    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title}>{title}</span>
            <Row vertical='center'>
                <div className={classes.separator}></div>
                <span className={classes.name}>{firstname}  {lastname}</span>
                <img
                    src={PROFILE_IMG_ROOT + avatarPath}
                    alt='avatar'
                    className={classes.avatar}
                />
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

function mapStateToProps(state) {
    const { entities } = state;
    return {
        data: entities.auth,
    }
}


export default connect(mapStateToProps)(HeaderComponent);
