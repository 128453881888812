import merge from 'lodash/merge';
import { Schemas } from '../store/schema';

const initialState = {
    auth: Schemas.AUTH,
    users: Schemas.USERS,
    bonsai: Schemas.BONSAI,
    tasks: Schemas.TASKS
};

const entities = (state = initialState, action) => {
    if (action.entities) {
        return merge({}, state, action.entities);
    }
    return state;
}

export default entities;